<template>
  <div class="wrapper">
    <logo top="100px" left="30px"></logo>
    <div class="box">
      <div class="title">診断結果</div>
      <div class="content">
        <goodneet v-if="result_id == 'goodneet'"></goodneet>
        <normalneet v-if="result_id == 'normalneet'"></normalneet>
        <citizen v-if="result_id == 'citizen'"></citizen>
        <syachiku v-if="result_id == 'syachiku'"></syachiku>
      </div>
      <div class="go_top">
        <router-link to="/">TOPヘ戻る</router-link>
      </div>
    </div>
  </div>
  <!-- <div>{{ result_id }}</div> -->
</template>

<script>
import goodneet from "@/components/result/goodneet.vue";
import normalneet from "@/components/result/normalneet.vue";
import citizen from "@/components/result/citizen.vue";
import syachiku from "@/components/result/syachiku.vue";
import logo from "@/components/parts/logo.vue";

export default {
  name: "result",
  components: {
    goodneet,
    normalneet,
    citizen,
    syachiku,
    logo,
  },
  data() {
    return {
      result_id: this.$route.params.result_id,
    };
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/img/result/bg.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 768px;
  max-width: 94%;
  padding: 0;
  background-color: #fff;
  border: 3px solid #6a3a80;
  border-radius: 4px;
  z-index: 10;
  position: relative;
}

.title {
  background-color: #6a3a80;
  color: #fff;
  text-align: center;
  font-size: 16px;
  padding: 8px 0;
  letter-spacing: 5px;
}

.content {
  padding: 32px 0;
  text-align: center;
}

.go_top {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}

.go_top a {
  font-size: 16px;
}

::v-deep .main_img {
  height: 224px;
}

::v-deep h2 {
  position: relative;
  color: #e6428d;
  font-size: 50px;
  line-height: 70px;
  font-weight: normal;
  margin-bottom: 24px;
}

::v-deep .text_container {
  margin-bottom: 24px;
}

::v-deep .text_container p {
  color: #e6428d;
  line-height: 32px;
  font-size: 20px;
}

::v-deep .go_btn {
  background-color: #e6428d;
  color: #fff;
  padding: 16px 32px;
  font-size: 20px;
  display: inline-block;
  border-radius: 40px;
  margin-bottom: 24px;
  text-decoration: none;
}

::v-deep .sns_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

::v-deep .sns_container img {
  width: 24px;
  height: 24px;
}

::v-deep .sns_container a {
  font-size: 16px;
  color: #1da1f2;
  padding-left: 4px;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    height: auto;
  }
  .box {
    margin: 96px 0;
  }

  .content {
    padding: 32px 3%;
  }

  ::v-deep h2 {
    font-size: 32px;
    line-height: 40px;
  }
  ::v-deep .text_container p {
    line-height: 26px;
    font-size: 18px;
  }
  ::v-deep .main_img {
  height: auto;
  width: 100%;
}
}
</style>
