<template>
  <img class="main_img" src="../../assets/img/result/syachiku.png" />
  <h2>危険！あなたは社畜です！！</h2>
  <div class="text_container">
    <p>社畜すぎるあなたは、一旦仕事をやめて</p>
    <p>グットニートハウスに住んで、ゆっくり人生を考え直しましょう。</p>
    <p>必死に働くことだけが、人生じゃないですよ。</p>
  </div>
  <a class="go_btn" href="https://www.taishokudaikou.com/" target="_blank">退職代行サービスへ</a>
  <div class="sns_container">
    <img src="../../assets/img/common/twitter.svg" />
    <a target="_blank" href="https://twitter.com/intent/tweet?url=URL&hashtags=グットニート診断,グットニートハウス&text=危険！あなたは社畜です！！%0A社畜すぎるあなたは、一旦仕事をやめてグットニートハウスに住んで、ゆっくり人生を考え直しましょう。必死に働くことだけが、人生じゃないですよ。%0A%0A">
      <span>SNSでシェアする</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "normalneet",
};
</script>

<style scoped>
.main_img{
  margin-bottom: 12px;
}
</style>