<template>
  <div class="wrapper">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13430.30000192293!2d128.8436947!3d32.6973453!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35149174fc1d6865%3A0x9f68b9903680c598!2z44Kw44OD44OI44OL44O844OI44OP44Km44K5IC0gZ29vZG5lZXQgaG91c2UgLSDms4rjgb7jgozjgovjgrfjgqfjgqLjg4_jgqbjgrk!5e0!3m2!1sja!2sjp!4v1679640868832!5m2!1sja!2sjp" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <h3>グットニートハウス</h3>
    <div class="text_container">
      <p>〒853-0006</p>
      <p>長崎県五島市江川町11-22</p>
    </div>
    <a
      class="question_button"
      href="https://www.google.co.jp/maps/place/%E7%A6%8F%E6%B1%9F%E5%B3%B6/@32.7056827,128.7432139,11.41z/data=!4m5!3m4!1s0x35148462ae841051:0xb4db0de53a950777!8m2!3d32.7018615!4d128.7577605?hl=ja"
      target="_blank"
    >
      <div class="text">GoogleMapで確認する</div>
      <div class="circle">
        <img src="../../assets/img/modal_content/arrow.svg" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "access",
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  padding: 12px 16px;
  position: relative;
}
iframe {
  width: 100%;
  display: block;
  margin-bottom: 16px;
  height: 314px;
}
h3 {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 8px;
}
.text_container {
  padding-left: 16px;
}
.text_container p {
  line-height: 32px;
}
.question_button {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #e6428d;
  text-decoration: none;
  padding: 12px 0;
  border-radius: 30px;
  width: max-content;
}

.question_button .text {
  font-size: 16px;
  color: #fff;
  display: block;
  padding: 0 24px;
}

.question_button .circle img {
  color: #e6428d;
}
.question_button .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 24px;
  width: 36px;
  height: 36px;
}
@media only screen and (max-width: 600px) {
  iframe{
    height: 312px;
  }
  .text_container p{
    line-height: 24px;
  }
}
@media only screen and (max-width: 550px) {
  iframe{
    height: 246px;
  }
}
@media only screen and (max-width: 475px) {
  iframe{
    height: calc(100% - 188px);
  }
}
@media only screen and (max-width: 420px) {
  iframe{
    height: calc(100% - 166px);
  }
  .question_button {
    padding: 8px 0;
    bottom: 16px;
  }
  .question_button .text {
    padding: 0 16px;
  }
  .question_button .circle {
    margin-right: 12px;
  }
  .wrapper{
    padding: 12px 8px;
  }
  h3{
    font-size: 18px;
  }
  .text_container p{
    font-size: 14px;
  }
}
@media only screen and (max-width: 360px) {
  .question_button .text {
    font-size: 14px;
  }
}
</style>
