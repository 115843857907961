import { createRouter, createWebHistory } from 'vue-router'
import top from '../pages/top.vue'
import question from '../pages/question.vue'
import result from '../pages/result.vue'
import check from '../pages/check.vue'

const routes = [
  {
    path: '/',
    name: 'top',
    component: top
  },
  {
    path: '/question',
    name: 'question',
    component: question
  },
  {
    path: '/check',
    name: 'check',
    component: check
  },
  {
    path: '/result/:result_id',
    name: 'result',
    component: result,
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
