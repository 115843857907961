<template>
  <div class="wrapper">
    <img src="../../assets/img/modal_content/master.png" />
    <h3>コバヤシヨシトモ（通称：コバ）</h3>
    <h4>グットニートカンパニー代表</h4>
    <div class="text_container">
      <p>2021年6月に五島列島の福江島に移住し、グットニートカンパニーをスタート。</p>
      <p>
        「グッとくるニートが、グッとくる世界をつくる」をテーマに活動するグットニートです。
      </p>
    </div>
    <div class="icon_container">
      <a href="https://www.facebook.com/yoshitomo.kobayashi.79" target="_blank">
        <img src="../../assets/img/common/facebook.svg" />
      </a>
      <a href="https://www.instagram.com/koba_goodneet/" target="_blank">
        <img src="../../assets/img/common/instagram.svg" />
      </a>
      <a href="https://twitter.com/koba_coandco" target="_blank">
        <img src="../../assets/img/common/twitter.svg" />
      </a>
      <a href="https://note.com/koba_goodneet" target="_blank">
        <img src="../../assets/img/common/note.svg" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "master",
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  padding: 12px 16px;
  position: relative;
}
img {
  width: 100%;
  display: block;
  margin-bottom: 16px;
}
h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  margin-bottom: 12px;
}
h4 {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 16px;
}
.text_container p {
  font-size: 10px;
  line-height: 16px;
}
.icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.icon_container a {
  margin: 0 4px;
}
.icon_container a img {
  width: 28px;
  height: 28px;
}
@media only screen and (max-width: 350px) {
  .img_container {
    width: 96px;
    height: 96px;
  }
  .icon_container img {
    margin-bottom: 0;
  }
}
</style>
