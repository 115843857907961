<template>
  <div class="firstview">
    <img class="bar top" src="../../assets/img/firstview/bar.png" />
    <img class="bar bottom" src="../../assets/img/firstview/bar.png" />
    <div class="logo_wrapper">
      <img class="logo" src="../../assets/img/common/logo.png" />
    </div>
    <p class="message top">ARE YOU GOOD NEET ?</p>
    <p class="message bottom">©︎2023 Good NEET company</p>
  </div>
  <img src="../../assets/img/firstview/tsutsu.png" class="tsutsu" />
</template>

<script>
export default {
  name: "firstview",
};
</script>

<style scoped>
.bg {
  height: 100vh;
  width: auto;
  margin: 0 auto;
  display: block;
}

.firstview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/img/firstview/bg.png");
  background-size: cover;
  background-position: center;
  border-top: 12px solid #69397f;
  border-bottom: 12px solid #69397f;
}

.firstview {
  animation: firstview_animation 4s linear;
  clip-path: inset(0 100% 0 0);
}
@keyframes firstview_animation {
  0% {
    clip-path: inset(0 0 0 0);
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 100% 0 0);
  }
}
.tsutsu{
  position: fixed;
  top: -2vh;
  height: 104vh;
  z-index: 101;
  right: calc(100%);
  animation: tsutsu_animation 4s linear;
}
@keyframes tsutsu_animation {
  0% {
    right: -16.5vh;
  }
  50% {
    right: -16.5vh;
  }
  100% {
    right: calc(100%);
  }
}

.bar {
  height: 28px;
  width: 100%;
  position: absolute;
}

.bar.top {
  top: 0;
}

.bar.bottom {
  bottom: 0;
}

.logo_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 50vw;
  max-width: 770px;
  min-width: 560px;
}

.message {
  color: #e6428d;
  font-size: 11px;
  letter-spacing: 10px;
  position: absolute;
  margin: 0 auto;
  display: block;
  line-height: 30px;
  width: 100%;
  text-align: center;
}

.message.top {
  top: 0;
}
.message.bottom {
  bottom: 0;
}

@media only screen and (max-width: 768px) {
  .logo {
    width: 80vw;
    max-width: auto;
    min-width: auto;
  }
}
@media only screen and (max-width: 600px) {
  .message.top {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .message {
    letter-spacing: 5px;
  }
}
</style>
