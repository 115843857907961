<template>
  <div class="img_btn_container" v-bind:class="class_name">
    <img src="../../assets/img/top/isu.png" />
    <div class="fukidashi_container">
      <div class="text_container">
        <p>働いたら負け</p>
        <span class="circle">
          <img src="../../assets/img/common/arrow_pink.svg" />
        </span>
      </div>
      <img src="../../assets/img/top/fukidashi.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "isu",
  data() {
    return {
      class_name: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.class_name = "animation";
    }, 1200);
  },
};
</script>

<style scoped>
.img_btn_container {
  width: 12.7%;
  bottom: 51.7%;
  left: 70.9%;
}

.fukidashi_container {
  width: 140%;
  top: -40%;
  left: -131%;
}

.text_container {
  margin-top: calc(50% - 14px);
}

.animation {
  animation: 8s kurukuru linear infinite;
}

@keyframes kurukuru {
  0% {
    transform: rotate(0);
  }
  18% {
    transform: rotate(140deg);
  }
  50% {
    transform: rotate(180deg);
  }
  68% {
    transform: rotate(320deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .img_btn_container {
    width: 12%;
    bottom: 33%;
    left: 52%;
  }
  .fukidashi_container {
    display: none!important;
  }
}
</style>
