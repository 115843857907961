<template>
  <div class="wrapper">
    <img src="../../assets/img/modal_content/umi.png" />
    <div class="bottom_container">
      <img src="../../assets/img/modal_content/yama.png" />
      <img src="../../assets/img/modal_content/kyoukai.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "landscape",
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  padding: 24px 8px;
  position: relative;
  overflow: auto;
}
img {
  width: 100%;
}
.bottom_container {
  padding-top: 6px;
  display: flex;
  justify-content: space-between;
}
.bottom_container img {
  width: calc(50% - 4px);
}
@media only screen and (max-width: 475px) {
  .wrapper {
    padding-top: 0;
  }
  .bottom_container {
    display: block;
    padding-top: 0;
  }
  .bottom_container img {
    width: 100%;
  }
  img{
    margin-bottom: 8px;
  }
}
</style>
