<template>
  <makimono v-bind:modal="modal" v-on:close_modal="closeModal"></makimono>
  <tv v-bind:tv_modal="tv_modal" v-on:close_tv_modal="closeTVModal"></tv>
  <firstview v-show="is_firstview_display"></firstview>
  <div class="wrapper">
    <kumo1 v-if="modal == 'none'" top="-10px" right="30px" class="kumo1"></kumo1>
    <kumo2 v-if="modal == 'none'" bottom="-10px" left="30px" class="kumo2"></kumo2>
    <kumo3 v-on:click="openTVModal"></kumo3>
    <logo top="100px" left="30px"></logo>
    <div class="content" v-show="is_content_display">
      <div class="left">
        <div class="left_container">
          <div class="title_container">
            <h2>
              遊ぶように働く、<br
                class="sp370"
              />ニート達の楽園<br />“グットニートハウス”<span class="space">爆誕</span>
            </h2>
            <span class="bg_title"></span>
          </div>
          <div class="text_container">
            <p>長崎県、五島列島の福江島に</p>
            <p>グットニートの楽園が誕生しました。</p>
            <p>遊ぶように働く ”グットなニート”</p>
            <p>ニートだけど気のいい ”グッとくるニート”</p>
            <p>そんな “グットニート” 達と</p>
            <p>ゆるく楽しく暮らしてみませんか？</p>
          </div>
          <router-link to="/check" class="question_button">
            <div class="text">グットニートライフを<br />楽しみたい人はこちら</div>
            <div class="circle">
              <img src="../assets/img/top/arrow.svg" />
            </div>
          </router-link>
        </div>
      </div>
      <div class="right">
        <kumo5 v-if="modal == 'none'" top="64px" right="-34px" class="kumo5"></kumo5>
        <kumo4 v-if="modal == 'none'" top="88px" left="-10px" class="kumo4"></kumo4>
        <img class="bottom_arrow" src="../assets/img/top/bottom_arrow.png" />
        <div class="img_container">
          <img class="house" src="../assets/img/top/house.png" />
          <koba v-on:click="openModal('master')" v-bind:is_hover="is_hover"></koba>
          <furo
            v-on:click="openModal('sponser')"
            v-on:mouseover="doHover"
            v-on:mouseleave="leaveHover"
          ></furo>
          <sleep
            v-on:click="openModal('landscape')"
            v-on:mouseover="doHover"
            v-on:mouseleave="leaveHover"
          ></sleep>
          <beer
            v-on:click="openModal('sharehouse')"
            v-on:mouseover="doHover"
            v-on:mouseleave="leaveHover"
          ></beer>
          <isu
            v-on:click="openModal('price')"
            v-on:mouseover="doHover"
            v-on:mouseleave="leaveHover"
          ></isu>
          <neko
            v-on:click="openModal('access')"
            v-on:mouseover="doHover"
            v-on:mouseleave="leaveHover"
          ></neko>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firstview from "@/components/top/firstview.vue";
import tv from "@/components/top/tv_modal.vue";
import makimono from "@/components/top/makimono_modal.vue";
import kumo1 from "@/components/parts/kumo1.vue";
import kumo2 from "@/components/parts/kumo2.vue";
import kumo3 from "@/components/parts/kumo3.vue";
import kumo4 from "@/components/parts/kumo4.vue";
import kumo5 from "@/components/parts/kumo5.vue";
import logo from "@/components/parts/logo.vue";
import furo from "@/components/parts/furo.vue";
import sleep from "@/components/parts/sleep.vue";
import neko from "@/components/parts/neko.vue";
import beer from "@/components/parts/beer.vue";
import isu from "@/components/parts/isu.vue";
import koba from "@/components/parts/koba.vue";

export default {
  name: "top",
  components: {
    firstview,
    makimono,
    tv,
    kumo1,
    kumo2,
    kumo3,
    kumo4,
    kumo5,
    logo,
    furo,
    sleep,
    neko,
    beer,
    isu,
    koba,
  },
  data() {
    return {
      is_hover: false,
      is_firstview_display: true,
      is_content_display: false,
      modal: "none",
      tv_modal: "none",
    };
  },
  mounted() {
    // 初期表示を消す
    setTimeout(() => {
      this.is_firstview_display = false;
    }, 4000);
    // 表示する
    setTimeout(() => {
      this.is_content_display = true;
    }, 1000);
  },
  methods: {
    openModal(code) {
      this.modal = code;
    },
    closeModal() {
      this.modal = "none";
    },
    openTVModal() {
      this.tv_modal = "show";
    },
    closeTVModal() {
      this.tv_modal = "none";
    },
    doHover() {
      this.is_hover = true;
    },
    leaveHover() {
      this.is_hover = false;
    },
  },
};
</script>

<style scoped>
.sp370 {
  display: none;
}
.wrapper {
  background-image: url("../assets/img/top/makimono_bg.png");
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 90px;
}
.content {
  width: 100%;
  max-width: 1100px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}
.left,
.right {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left {
  width: 40%;
}
.right {
  width: 60%;
}
/* right */
.img_container {
  width: 100%;
  height: min-content;
  position: relative;
}
img.house {
  width: 100%;
}
.bottom_arrow {
  display: none;
}

::v-deep .img_btn_container {
  position: absolute;
  cursor: pointer;
}

::v-deep .img_btn_container img {
  width: 100%;
}

::v-deep .img_btn_container .fukidashi_container {
  display: none;
  position: absolute;
  text-align: center;
}

::v-deep .img_btn_container:hover .fukidashi_container {
  display: block;
}

::v-deep .img_btn_container .text_container {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-size: 10px;
}

::v-deep .img_btn_container .circle {
  margin-top: 10%;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

::v-deep .img_btn_container .circle img {
  width: 8px;
}

/* left */
.left_container {
  padding-left: 15px;
  width: 100%;
  height: min-content;
  position: relative;
}
.title_container {
  margin-bottom: 24px;
  height: min-content;
  display: inline-block;
  position: relative;
}
h2 {
  font-size: 25px;
  color: #e6428d;
  line-height: 40px;
  display: inline-block;
  position: relative;
  z-index: 1;
}
h2 .space {
  padding-left: 16px;
}
.bg_title {
  position: absolute;
  background-color: #ffefef;
  width: calc(100% + 30px);
  height: 60%;
  left: -15px;
  z-index: 0;
  top: 25%;
}

.text_container {
  margin-bottom: 24px;
}

.text_container p {
  color: #e6428d;
  font-size: 16px;
  line-height: 36px;
  font-weight: bold;
}

.question_button {
  position: relative;
  top: 0;
  left: -15px;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #69397d;
  text-decoration: none;
  padding: 16px 0;
  border-radius: 8px;
  box-shadow: 0 8px 0 rgba(105, 57, 125, 0.15);
  transition: all 0.3s;
}
.question_button:hover {
  top: 8px;
  box-shadow: 0 0 0 rgba(105, 57, 125, 0.15);
  opacity: 0.7;
}

.question_button .text {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #fff;
  display: block;
  padding: 0 40px;
}

.question_button .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 24px;
  width: 36px;
  height: 36px;
}

.kumo4,
.kumo5 {
  display: none;
}

@media only screen and (max-width: 1160px) {
  .wrapper {
    padding: 90px 3%;
  }
}
@media only screen and (max-width: 1050px) {
  .wrapper {
    background-image: url("../assets/img/top/makimono_bg_sp.png");
    height: auto;
  }
  .content {
    display: block;
  }
  .right,
  .left {
    width: 100%;
  }
  .left_container {
    width: 65%;
    min-width: 560px;
    margin: 80px auto 100px;
  }
  .img_container {
    min-width: 700px;
    width: 75%;
  }
}

@media only screen and (max-width: 600px) {
  .left_container {
    min-width: auto;
    width: 100%;
    margin: 52px auto 40px;
  }
  .text_container {
    margin-bottom: 64px;
  }
  .right {
    display: block;
  }
  .img_container {
    min-width: auto;
    width: 150%;
    left: -12%;
  }
  .bottom_arrow {
    display: block;
    margin: 0 auto 240px;
    width: 48px;
  }
  .kumo1,
  .kumo2 {
    display: none;
  }
  .kumo4,
  .kumo5 {
    display: block;
  }
}

@media only screen and (max-width: 430px) {
  h2 {
    font-size: 22px;
  }
  .text_container p {
    font-size: 14px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 370px) {
  .sp370 {
    display: block;
  }
  h2 {
    line-height: 32px;
    font-size: 21px;
  }
  .bg_title {
    height: 70%;
    top: 15%;
  }
  .question_button {
    width: calc(100% + 15px);
  }
  .question_button .text {
    padding: 0 35px;
  }
}
</style>
