<template>
  <div class="wrapper">
    <div class="container">
      <div class="row">
        <div class="img_container">
          <img class="animato" src="../../assets/img/modal_content/animato.png" />
        </div>
        <p>合同会社アニマート</p>
      </div>
      <div class="row">
        <div class="img_container">
          <img class="kurasu" src="../../assets/img/modal_content/kurasu.png" />
        </div>
        <p>くらす企画</p>
      </div>
      <div class="row">
        <div class="img_container">
          <img class="moteasobu" src="../../assets/img/modal_content/moteasobu.png" />
        </div>
        <p>モテアソブ三軒茶屋</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sponser",
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  padding: 24px 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 318px;
  height: min-content;
}

.row {
  width: 100%;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.row p {
  color: #e6428d;
  font-size: 20px;
}

.img_container {
  width: 104px;
  height: 104px;
  border: 1px solid #e6428d;
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animato {
  width: 60%;
}

.kurasu {
  width: 90%;
}

.moteasobu {
  width: 100%;
}

@media only screen and (max-width: 425px) {
  .img_container{
    width: 96px;
    height: 96px;
  }
  .row p{
    font-size: 16px;
  }
}
@media only screen and (max-width: 350px) {
  .img_container{
    width: 96px;
    height: 96px;
  }
  .row p{
    font-size: 16px;
  }
  .img_container{
    margin-right: 8px
  }
}
</style>
