<template>
  <div class="wrapper">
    <logo top="30px" left="30px"></logo>
    <kumo1 top="20vh" right="30px" is_white="true"></kumo1>
    <kumo2 bottom="10vh" left="30px" is_white="true"></kumo2>
    <div class="box">
      <question1 v-if="mode == 'q1'" v-on:next="next"></question1>
      <question2 v-if="mode == 'q2'" v-on:next="next"></question2>
      <question3 v-if="mode == 'q3'" v-on:next="next"></question3>
      <question4 v-if="mode == 'q4'" v-on:next="next"></question4>
      <question5 v-if="mode == 'q5'" v-on:next="next"></question5>
      <question6 v-if="mode == 'q6'" v-on:next="next"></question6>
      <announcement v-if="mode == 'q7'" v-on:next="next"></announcement>
      <p class="tate_message">ARE YOU GOOD NEET ?</p>
    </div>
  </div>
</template>

<script>
import kumo1 from "@/components/parts/kumo1.vue";
import kumo2 from "@/components/parts/kumo2.vue";
import logo from "@/components/parts/logo.vue";
import question1 from "@/components/question/question1.vue";
import question2 from "@/components/question/question2.vue";
import question3 from "@/components/question/question3.vue";
import question4 from "@/components/question/question4.vue";
import question5 from "@/components/question/question5.vue";
import question6 from "@/components/question/question6.vue";
import announcement from "@/components/question/announcement.vue";
import router from "../router";
export default {
  name: "question",
  components: {
    logo,
    kumo1,
    kumo2,
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    announcement,
  },
  data() {
    return {
      mode: "q1",
      point: 0,
      is_syachiku: false,
      normal_point: false,
    };
  },
  methods: {
    // 質問に回答した時に動く
    next(point, normal_point, is_syachiku) {
      
      // データの保存
      this.point = this.point + point;
      this.normal_point = this.normal_point + normal_point;
      if (is_syachiku) this.is_syachiku = true;

      // qは次のモードへ
      if (this.mode != "q7") {
        const mode_val = this.mode.replace("q", "");
        const next_mode = "q" + String(Number(mode_val) + 1);
        this.mode = next_mode;

        return;
      }

      this.transferResult();
    },

    transferResult: function () {
      let result_id = "";

      if (this.point < 0) {
        result_id = "normalneet";
      } else if (this.point < 15) {
        result_id = "citizen";
      } else {
        result_id = "goodneet";
      }

      // ノーマルニート3なら強制的にノーマルニート
      if(this.normal_point == 3) result_id = "normalneet";

      // 社畜は選択すると強制的に社畜
      if (this.is_syachiku) result_id = "syachiku";

      router.push({
        name: "result",
        params: {
          result_id: result_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/img/question/bg.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.box {
  width: 560px;
  max-width: 90%;
  padding: 0 46px 16px;
  background-color: #fff;
  border: 3px solid #6a3a80;
  border-radius: 4px;
  z-index: 10;
  position: relative;
}

::v-deep .bar_wrapper {
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .bar {
  width: 15%;
  height: 6px;
  border-radius: 3px;
  background-color: #f9d0e3;
}

::v-deep .bar.active {
  background-color: #e6428d;
}

::v-deep .img {
  width: 100%;
  height: 320px;
  background-color: gray;
  background-size: cover;
  background-position: center;
}

::v-deep .question_wrapper {
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

::v-deep .question_number {
  width: 88px;
  height: 88px;
  margin-right: 12px;
  border-radius: 50%;
  border: 1px solid #e6428d;
  color: #e6428d;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .question_text {
  width: calc(100% - 100px);
  font-size: 30px;
  line-height: 45px;
  color: #e6428d;
}

::v-deep .input_container {
  width: 100%;
  padding: 8px;
  border: 1px solid #707070;
  margin-bottom: 16px;
  border-radius: 8px;
  display: block;
  position: relative;
  cursor: pointer;
  text-align: center;
}

::v-deep .input_container:hover {
  background-color: #e6428d;
  border: 1px solid #e6428d;
  color: #fff;
}

::v-deep .input_container:after {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  border-radius: 50%;
  left: 12px;
}

::v-deep .input_container:hover:after {
  border: 2px solid #fff;
  width: 13px;
  height: 13px;
}

::v-deep .input_container.half:after {
  display: none;
}

::v-deep .tate_message {
  color: #e6428d;
  font-size: 20px;
  letter-spacing: 20px;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: absolute;
  top: 35px;
  right: -40px;
}

@media only screen and (max-width: 768px) {
  .box {
    max-width: 94%;
    padding: 0 3% 16px;
  }
  ::v-deep .tate_message {
    display: none;
  }
  ::v-deep .question_number {
    width: 72px;
    height: 72px;
  }
  ::v-deep .question_text {
    width: calc(100% - 72px);
  }
  ::v-deep .question_text {
    font-size: 24px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 600px) {
  .wrapper{
    height: auto;
  }
    .box{
    margin: 96px 0;
  }
}
@media only screen and (max-width: 450px) {
  ::v-deep .question_number {
    width: 56px;
    height: 56px;
  }
  ::v-deep .question_text {
    width: calc(100% - 56px);
  }
  ::v-deep .question_text {
    font-size: 20px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 360px) {
  ::v-deep .input_container {
    font-size: 14px;
  }
  ::v-deep .input_container:after {
    width: 12px;
    height: 12px;
    left: 8px;
  }
}
</style>
