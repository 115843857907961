<template>
  <img class="main_img" src="../../assets/img/result/goodneet.png" />
  <h2>あなたはグットニートです！</h2>
  <div class="text_container">
    <p>おめでとうございます！</p>
    <p>グットニートハウスに住む権利を取得しました！</p>
    <p>早速、予約してみましょう！</p>
  </div>
  <a class="go_btn" href="https://forms.gle/6awwoBmmU8dyZ3Sw7" target="_blank">予約フォームへ</a>
  <div class="sns_container">
    <img src="../../assets/img/common/twitter.svg" />
    <a target="_blank" href="https://twitter.com/intent/tweet?url=URL&hashtags=グットニート診断,グットニートハウス&text=あなたはグットニートです！%0Aおめでとうございます！グットニートハウスに住む権利を取得しました！早速、予約してみましょう！%0A%0A">
      <span>SNSでシェアする</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "goodneet",
};
</script>

