<template>
  <div class="bar_wrapper">
    <span class="bar active"></span>
    <span class="bar"></span>
    <span class="bar"></span>
    <span class="bar"></span>
    <span class="bar"></span>
    <span class="bar"></span>
  </div>
  <div class="img"></div>
  <div class="question_wrapper">
    <div class="question_number">Q1</div>
    <p class="question_text">あなたの友達はどれくらいいますか？</p>
  </div>
  <div class="input_container" v-on:click="next('a')">〜 100人</div>
  <div class="input_container" v-on:click="next('b')">100 〜 1000人</div>
  <div class="input_container" v-on:click="next('c')">1000人以上</div>  
</template>

<script>
export default {
  name: "question1",
  methods: {
    next(input_value) {
      let point = 0;
      let normal_point = 0;
      let is_syachiku = false;
      
      if(input_value == "a"){
        point = -2;
        is_syachiku = true;

      }else if(input_value == "c"){
        point = 5;
      }

      this.$emit("next", point, normal_point, is_syachiku);
    },
  },
};
</script>

<style scoped>
.img {
  background-image: url("../../assets/img/question/question_1.png");
}
</style>
