<template>
  <div class="wrapper">
    <kumo2 top="20px" right="-40px" class="kumo2"></kumo2>
    <logo top="56px" left="30px"></logo>
    <div class="container">
      <div class="bg"></div>
      <span class="left_text">GOOD</span>
      <span class="right_text">NEET?</span>
      <span class="center_text">GOODNEET?</span>
      <div class="box">
        <div class="box_container">
          <div class="title_container">
            <h2>グットニート診断を<br />受けますか？</h2>
            <span class="bg_title"></span>
          </div>
          <div class="text_container">
            <p>
              グットニートハウスに住むには、グットニートの素質を持っている必要があります。グットニート診断を受けて、グットニートハウスに住もう！
            </p>
          </div>
          <router-link to="/question" class="question_button">
            <div class="text">診断スタート</div>
            <div class="circle">
              <img src="../assets/img/common/arrow_pink.svg" />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kumo2 from "@/components/parts/kumo2.vue";
import logo from "@/components/parts/logo.vue";

export default {
  name: "check",
  components: {
    kumo2,
    logo,
  },
};
</script>

<style scoped>
.wrapper {
  background-image: url("../assets/img/top/makimono_bg.png");
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 8.6vh 0 7.6vh;
}
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.bg {
  background-image: url("../assets/img/check/bg.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 55%;
  position: absolute;
  top: 0;
  left: 0;
}
.center_text{
  display: none;
}
.left_text,
.right_text,
.center_text
 {
  position: absolute;
  font-size: 72px;
  letter-spacing: 33px;
  color: #e6428d;
  top: 59%;
}
.left_text {
  right: calc(50% + 268px);
}
.right_text {
  left: calc(50% + 300px);
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  width: 480px;
  height: 560px;
  border: 3px solid #6a3a80;
  background-color: #fff;
  z-index: 10;
  box-shadow: 8px 8px 0 rgb(106, 58, 128);
  border-radius: 4px;
}
.box_container {
  position: relative;
  max-width: 90%;
  width: 336px;
  height: 372px;
}
.title_container {
  margin-bottom: 24px;
  height: min-content;
  display: inline-block;
  position: relative;
}
h2 {
  font-size: 25px;
  color: #e6428d;
  line-height: 40px;
  display: inline-block;
  position: relative;
  letter-spacing: 4px;
  z-index: 1;
}
.bg_title {
  position: absolute;
  background-color: #ffefef;
  width: calc(100% + 20px);
  height: 60%;
  left: 0;
  z-index: 0;
  top: 25%;
}

.text_container {
  margin-bottom: 32px;
}

.text_container p {
  color: #e6428d;
  font-size: 16px;
  line-height: 36px;
  font-weight: bold;
}

.question_button {
  position: relative;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #e6428d;
  text-decoration: none;
  padding: 16px 0;
  border-radius: 8px;
  box-shadow: 0 8px 0 rgba(105, 57, 125, 0.15);
  transition: all 0.3s;
  top: 0;
}
.question_button:hover {
  top: 8px;
  box-shadow: 0 0 0 rgba(105, 57, 125, 0.15);
  opacity: 0.7;
}

.question_button .text {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #fff;
  display: block;
}

.question_button .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  width: 36px;
  height: 36px;
  position: absolute;
  right: 15px;
}

@media only screen and (max-width: 1350px) {
  .left_text {
    right: calc(50% + 248px);
    letter-spacing: 25px;
  }
  .right_text {
    left: calc(50% + 280px);
    letter-spacing: 25px;
  }
}

@media only screen and (max-width: 1280px) {
  .left_text {
    right: calc(50% + 240px);
    letter-spacing: 15px;
  }
  .right_text {
    left: calc(50% + 265px);
    letter-spacing: 15px;
  }
}

@media only screen and (max-width: 1110px) {
  .box{
      width: 432px;
      height: 488px;
  }
  .left_text,
  .right_text{
    display: none;
  }

  .center_text{
    display: block;
    bottom: 24px;
    padding-left: 29px;
    top: auto;
    font-size: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    background-image: url("../assets/img/check/makimono_bg_sp.png");
    padding: 4vh 0 3.8vh;
  }

  .center_text{
    bottom: 56px;
  }
}
@media only screen and (max-width: 600px) {
 .center_text{
   letter-spacing: 24px;
 }
 .kumo2{
   display: none;
 }
}

@media only screen and (max-width: 470px) {
 .center_text{
   font-size: 24px;
   bottom: 36px;
 } 
}

@media only screen and (max-width: 350px) {
 h2{
   font-size: 22px;
 }
 .center_text{
   font-size: 20px;
   letter-spacing: 16px;
 }
}
</style>
