<template>
  <img
    v-bind:src="img_path"
    v-bind:style="{ top: top, right: right, bottom: bottom, left: left }"
  />
</template>

<script>
export default {
  name: "kumo2",
  props: {
    top: {
      type: String,
      default: "auto",
    },
    right: {
      type: String,
      default: "auto",
    },
    bottom: {
      type: String,
      default: "auto",
    },
    left: {
      type: String,
      default: "auto",
    },
    is_white: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      img_path: this.getImgPath(),
    };
  },
  methods: {
    getImgPath() {
      return require("@/assets/img/common/kumo_4.svg");
    },
  },
};
</script>

<style scoped>
img {
  position: absolute;
  width: 47vw;
  z-index: 10;
  animation: 3s yurayura infinite;
}

@keyframes yurayura {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
}

</style>
