<template>
  <div class="makimono_modal" v-if="modal_code != 'none'" v-on:click="close">
    <kumo1 class="kumo1" top="-10px" right="30px" is_white="true"></kumo1>
    <kumo2 class="kumo2" bottom="-10px" left="30px" is_white="true"></kumo2>    
    <div class="back_container">
      <img src="../../assets/img/modal_content/close.svg" class="circle" />
      <span>トップに戻る</span>
    </div>
    <div class="makimono_wrapper" v-on:click.stop>
      <div class="back_btn" v-on:click="close">×</div>
      <img class="makimono pc" src="../../assets/img/top/makimono_modal_pc.png" />
      <img class="makimono sp" src="../../assets/img/top/makimono_modal_sp.png" />
      <div class="title">{{ title }}</div>
      <div class="makimono_container">
        <div class="container">
          <master v-if="modal == 'master'"></master>
          <access v-if="modal == 'access'"></access>
          <price v-if="modal == 'price'"></price>
          <landscape v-if="modal == 'landscape'"></landscape>
          <sponser v-if="modal == 'sponser'"></sponser>
          <sharehouse v-if="modal == 'sharehouse'"></sharehouse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kumo1 from "@/components/parts/kumo1.vue";
import kumo2 from "@/components/parts/kumo2.vue";
import master from "@/components/modal_content/master.vue";
import access from "@/components/modal_content/access.vue";
import price from "@/components/modal_content/price.vue";
import landscape from "@/components/modal_content/landscape.vue";
import sponser from "@/components/modal_content/sponser.vue";
import sharehouse from "@/components/modal_content/sharehouse.vue";
export default {
  name: "makimono_modal",
  components: {
    kumo1,
    kumo2,
    master,
    access,
    price,
    landscape,
    sponser,
    sharehouse,
  },
  props: {
    modal: {
      type: String,
      default: null,
    },
  },
  watch: {
    modal: {
      handler: function (new_modal) {
        this.modal_code = new_modal;
        this.title = this.getModalTitle();
      },
    },
  },
  data(props) {
    return {
      modal_code: props.modal,
      title: this.getModalTitle(),
    };
  },
  methods: {
    close() {
      // this.modal_code = "none";
      this.$emit("close_modal");
    },
    getModalTitle() {
      if (this.modal_code == "master") {
        return "支配人";
      }
      if (this.modal_code == "access") {
        return "アクセス";
      }
      if (this.modal_code == "price") {
        return "料金表";
      }
      if (this.modal_code == "landscape") {
        return "福江島の風景";
      }
      if (this.modal_code == "sponser") {
        return "協賛スポンサー";
      }
      if (this.modal_code == "sharehouse") {
        return "姉妹校提携シェアハウス";
      }

      return null;
    },
  },
};
</script>

<style scoped>
.makimono_modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}
.makimono {
  width: 538px;
}
.makimono.sp {
  display: none;
}

.title {
  width: 95%;
  position: absolute;
  text-align: center;
  font-size: 24px;
  font-weight:bold;
  letter-spacing: 2px;
  color: #fff;
  top: 28px;
}

.back_container {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: absolute;
  color: #fff;
  right: calc(50% - 500px);
  font-size: 24px;
  letter-spacing: 3px;
  cursor: pointer;
  top: 0;
  bottom: 0;
  text-align: center;
}

.circle {
  display: inline-flex;
  position: relative;
  width: 36px;
  height: 36px;
  margin-bottom: 8px;
}

.makimono_wrapper {
  width: min-content;
  height: min-content;
  position: relative;
  z-index: 15;
}

.makimono_container {
  width: calc(100% - 8px);
  height: 100%;
  top: 0;
  position: absolute;
  padding: 90px 14px 6px;
}

.container {
  width: 100%;
  height: 100%;
}

.back_btn {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .back_container {
    right: 50px;
  }
}

@media only screen and (max-width: 970px) {
  .back_container {
    writing-mode: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    left: 0;
    bottom: 24px;
    top: auto;
  }
  .circle {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  .makimono {
    width: 94vw;
  }
  .title {
    top: 4.5%;
    font-size: 18px;
  }
  .kumo1, .kumo2{
    display: none;
  }
}

@media only screen and (max-width: 475px) {
  .makimono.pc {
    display: none;
  }
  .makimono.sp {
    display: block;
  }
  .back_container {
    display: none;
  }
  .back_btn {
    display: block;
    color: #fff;
    font-size: 26px;
    position: absolute;
    top: 3.7%;
    right: 23px;
    z-index: 30;
    font-weight: bold;
  }
}
</style>
