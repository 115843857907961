<template>
  <img class="main_img" src="../../assets/img/result/citizen.png" />
  <h2>あなたは一般市民です！</h2>
  <div class="text_container">
    <p>一般市民のあなたには、快適でサービスの充実した</p>
    <p>HafHがオススメです！</p>
    <p>やりたいことしかしないニートの楽園には合わないかも？</p>
  </div>
  <a class="go_btn" href="https://www.hafh.com/" target="_blank">HafHへ</a>
  <div class="sns_container">
    <img src="../../assets/img/common/twitter.svg" />
    <a target="_blank" href="https://twitter.com/intent/tweet?url=URL&hashtags=グットニート診断,グットニートハウス&text=あなたは一般市民です！%0A一般市民のあなたには、快適でサービスの充実したHafHがオススメです！やりたいことしかしないニートの楽園には合わないかも？%0A%0A">
      <span>SNSでシェアする</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "normalneet",
};
</script>

