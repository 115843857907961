<template>
  <div class="wrapper">
    <div class="text_container">
      <h3>姉妹校提携シェアハウス</h3>
      <p>姉妹校提携しているシェアハウス同士では</p>
      <p>お互いのシェアハウスに自由に泊まりに行くことが可能です。</p>
    </div>
    <div class="icon_container">
      <div class="row">
        <a href="https://gakuen.moteamasu.jp/" target="_blank">
          <img src="../../assets/img/modal_content/moteamasu.png" />
        </a>
        <a href="https://www.facebook.com/TokyoMiraijinAjito/" target="_blank">
          <img src="../../assets/img/modal_content/ajito.png" />
        </a>
        <a href="https://liverty-house.com/directories/kitaalps" target="_blank">
          <img src="../../assets/img/modal_content/ribatei.png" />
        </a>
      </div>
    </div>
    <a class="question_button" href="https://forms.gle/6awwoBmmU8dyZ3Sw7" target="_blank">
      <div class="text">姉妹校シェアハウス募集中</div>
      <div class="circle">
        <img src="../../assets/img/modal_content/arrow.svg" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "sharehouse",
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  padding: 24px 36px;
  position: relative;
}
.text_container {
  padding-left: 16px;
  margin-bottom: 24px;
}
.text_container h3 {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 24px;
}
.text_container p {
  font-size: 10px;
  line-height: 16px;
}
.icon_container {
  border: 1px solid #69397f;
  width: 100%;
  height: 296px;
  padding: 16px;
  overflow: auto;
}
.icon_container .row {
  display: flex;
  justify-content: space-between;
}
.icon_container .row a {
  width: calc(30% - 8px);
}
.icon_container .row a img {
  width: 100%;
  border: 1px solid #e6428d;
}
.question_button {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #e6428d;
  text-decoration: none;
  padding: 12px 0;
  border-radius: 30px;
  width: max-content;
}

.question_button .text {
  font-size: 16px;
  color: #fff;
  display: block;
  padding: 0 24px;
}

.question_button .circle img {
  color: #e6428d;
}
.question_button .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 24px;
  width: 36px;
  height: 36px;
}

@media only screen and (max-width: 540px) {
  .icon_container {
    height: 224px;
  }
  .text_container {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 475px) {
  .text_container {
    padding-left: 0;
  }
  .icon_container {
    height: calc(100% - 168px);
    padding: 2%;
  }
  .icon_container .row {
    display: block;
  }
  .icon_container .row a img {
    width: 44%;
    margin: 3%;
  }
}

@media only screen and (max-width: 420px) {
  .text_container {
    padding-left: 0;
  }
  .icon_container {
    height: calc(100% - 184px);
    padding: 2%;
  }
  .icon_container .row {
    display: block;
  }
  .icon_container .row a img {
    width: 44%;
    margin: 3%;
  }
  .wrapper {
    padding: 16px;
  }
  .question_button {
    padding: 8px 0;
    bottom: 16px;
  }
  .question_button .text {
    padding: 0 16px;
  }
  .question_button .circle {
    margin-right: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .wrapper {
    padding: 0 8px 8px;
  }
  .question_button .text {
    font-size: 14px;
  }
}
</style>
