<template>
  <img class="main_img" src="../../assets/img/result/normalneet.png" />
  <h2>あなたはノーマルニートです</h2>
  <div class="text_container">
    <p>働かなくてもいいので</p>
    <p>まずは遊びまくって</p>
    <p>友達をいっぱい作るところから始めましょう！</p>
  </div>
  <a class="go_btn" href="https://tinder.com/ja" target="_blank">Tinderへ</a>
  <div class="sns_container">
    <img src="../../assets/img/common/twitter.svg" />
    <a target="_blank" href="https://twitter.com/intent/tweet?url=URL&hashtags=グットニート診断,グットニートハウス&text=あなたはノーマルニートです%0A働かなくてもいいのでまずは遊びまくって友達をいっぱい作るところから始めましょう！%0A%0A">
      <span>SNSでシェアする</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "normalneet",
};
</script>

