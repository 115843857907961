<template>
  <div class="bar_wrapper">
    <span class="bar active"></span>
    <span class="bar active"></span>
    <span class="bar active"></span>
    <span class="bar active"></span>
    <span class="bar active"></span>
    <span class="bar"></span>
  </div>
  <div class="img"></div>
  <div class="question_wrapper">
    <div class="question_number">Q5</div>
    <p class="question_text">大変な仕事を依頼されたときは？</p>
  </div>
  <div class="input_container" v-on:click="next('a')">死んでもやり遂げる</div>
  <div class="input_container" v-on:click="next('b')">しれっと逃げる</div>
  <div class="input_container" v-on:click="next('c')">誰かに任せる</div>
</template>

<script>
export default {
  name: "question5",
  methods: {
    next(input_value) {
      let point = 0;
      let is_syachiku = false;
      let normal_point = 0;

      if (input_value == "a") {
        point = 5;
        is_syachiku = true;
      } else if (input_value == "b") {
        point = -5;
        normal_point += 1;
      } else if (input_value == "c") {
        point = 5;
      }

      this.$emit("next", point, normal_point, is_syachiku);
    },
  },
};
</script>

<style scoped>
.img {
  background-image: url("../../assets/img/question/question_5.png");
}
</style>
