<template>
  <div class="img_btn_container" v-bind:class="class_name">
    <img src="../../assets/img/top/neko.png" />
    <div class="fukidashi_container">
      <div class="text_container">
        <p>毎日が連休</p>
        <span class="circle">
          <img src="../../assets/img/common/arrow_pink.svg" />
        </span>
      </div>
      <img src="../../assets/img/top/fukidashi.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "neko",
  data() {
    return {
      class_name: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.class_name = "animation";
    }, 1000);
  },
};
</script>

<style scoped>
.img_btn_container {
  width: 16%;
  bottom: 25.8%;
  left: 11.7%;
}

.fukidashi_container {
  width: 100%;
  top: -48%;
  left: -48%;
}

.text_container {
  margin-top: calc(50% - 14px);
}

.animation {
  animation: 4.5s fuwafuwa infinite;
}

@keyframes fuwafuwa {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media only screen and (max-width: 600px) {
  .img_btn_container {
    width: 18%;
    bottom: -8%;
    left: 6%;
  }
  .fukidashi_container {
    display: none!important;
  }
}
</style>
