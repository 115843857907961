<template>
  <div class="bar_wrapper">
    <span class="bar active"></span>
    <span class="bar active"></span>
    <span class="bar active"></span>
    <span class="bar active"></span>
    <span class="bar active"></span>
    <span class="bar active"></span>
  </div>
  <div class="question_wrapper">
    <div class="question_number">Q6</div>
    <p class="question_text">あなたの趣味・特技は？</p>
  </div>
  <p class="hosoku">（複数選択可能）</p>
  <label for="value_1" class="input_container half">
    <input type="checkbox" value="1" id="value_1" v-model="input_value_1" />
    <span>お金儲け</span>
  </label>
  <label for="value_2" class="input_container half">
    <input type="checkbox" value="1" id="value_2" v-model="input_value_2" />
    <span>ヒッチハイク</span>
  </label>
  <label for="value_3" class="input_container half">
    <input type="checkbox" value="1" id="value_3" v-model="input_value_3" />
    <span>ラジオパーソナリティ</span>
  </label>
  <label for="value_4" class="input_container half">
    <input type="checkbox" value="1" id="value_4" v-model="input_value_4" />
    <span>スリランカ料理</span>
  </label>
  <label for="value_5" class="input_container half">
    <input type="checkbox" value="1" id="value_5" v-model="input_value_5" />
    <span>DIY</span>
  </label>
  <label for="value_6" class="input_container half">
    <input type="checkbox" value="1" id="value_6" v-model="input_value_6" />
    <span>唐揚げ</span>
  </label>
  <label for="value_7" class="input_container half">
    <input type="checkbox" value="1" id="value_7" v-model="input_value_7" />
    <span>モテる</span>
  </label>
  <label for="value_8" class="input_container half">
    <input type="checkbox" value="1" id="value_8" v-model="input_value_8" />
    <span>トイレそうじ</span>
  </label>
  <label for="value_9" class="input_container half">
    <input type="checkbox" value="1" id="value_9" v-model="input_value_9" />
    <span>補助金ハンター</span>
  </label>
  <label for="value_10" class="input_container half">
    <input type="checkbox" value="1" id="value_10" v-model="input_value_10" />
    <span>友達作り</span>
  </label>
  <label for="value_11" class="input_container half">
    <input type="checkbox" value="1" id="value_11" v-model="input_value_11" />
    <span>虫料理</span>
  </label>
  <label for="value_12" class="input_container half">
    <input type="checkbox" value="1" id="value_12" v-model="input_value_12" />
    <span>パパ・ママ活</span>
  </label>
  <label for="value_13" class="input_container half">
    <input type="checkbox" value="1" id="value_13" v-model="input_value_13" />
    <span>釣り（魚または異性可）</span>
  </label>
  <div class="send_btn" v-on:click="next">診断結果を送る</div>
</template>

<script>
export default {
  name: "question6",
  data() {
    return {
      input_value_1: null,
      input_value_2: null,
      input_value_3: null,
      input_value_4: null,
      input_value_5: null,
      input_value_6: null,
      input_value_7: null,
      input_value_8: null,
      input_value_9: null,
      input_value_10: null,
      input_value_11: null,
      input_value_12: null,
      input_value_13: null,
    };
  },
  methods: {
    next() {
      let point = 0;
      let is_syachiku = false;
      let normal_point = 0;

      if (this.input_value_1) point += 5;
      if (this.input_value_2) point += 3;
      if (this.input_value_3) point += 5;
      if (this.input_value_4) point += 4;
      if (this.input_value_5) point += 3;
      if (this.input_value_6) point += 1;
      if (this.input_value_7) point += 4;
      if (this.input_value_8) point += 1;
      if (this.input_value_9) point += 3;
      if (this.input_value_10) point += 1;
      if (this.input_value_11) point += 3;
      if (this.input_value_12) point += 3;
      if (this.input_value_13) point += 3;

      this.$emit("next", point, normal_point, is_syachiku);
    },
  },
};
</script>

<style scoped>
.hosoku {
  color: #e6428d;
  padding: 16px 0 12px 0;
}
.input_container.half {
  width: 48%;
  display: inline-block;
}
.input_container.half:nth-child(2n + 1) {
  margin-left: 1%;
}
.input_container.half:nth-child(2n) {
  margin-right: 1%;
}
input[type="checkbox"] {
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.send_btn {
  width: 48%;
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid #e6428d;
  background-color: #e6428d;
  color: #fff;
  cursor: pointer;
  margin-bottom: 16px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 1%;
}

@media only screen and (max-width: 520px) {
  .input_container.half,
  .input_container.half:nth-child(2n + 1),
  .send_btn {
    width: 100%;
    margin: 0 0 12px;
  }
  .send_btn {
    text-align: center;
  }
}
</style>
