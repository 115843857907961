<template>
  <div class="img_btn_container" v-bind:class="class_name">
    <img src="../../assets/img/top/sleep.png" />
    <div class="fukidashi_container">
      <div class="text_container">
        <p>もう少し寝よう</p>
        <span class="circle">
          <img src="../../assets/img/common/arrow_pink.svg" />
        </span>
      </div>
      <img src="../../assets/img/top/fukidashi.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "sleep",
  data() {
    return {
      class_name: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.class_name = "animation";
    }, 2800);
  },
};
</script>

<style scoped>
.img_btn_container {
  width: 25.8%;
  bottom: 80.6%;
  left: 0.2%;
}

.fukidashi_container {
  width: 80%;
  top: -28%;
  left: -39%;
}

.text_container {
  margin-top: calc(50% - 14px);
}

.animation {
  animation: 4.5s fuwafuwa infinite;
}

@keyframes fuwafuwa {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media only screen and (max-width: 600px) {
  .img_btn_container {
    width: 22%;
    bottom: 76.6%;
    left: 4.2%;
  }
  .fukidashi_container {
    display: none!important;
  }
}
</style>
