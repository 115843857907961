<template>
  <div class="img_btn_container" v-bind:class="class_name">
    <img src="../../assets/img/top/beer.png" />
    <div class="fukidashi_container">
      <div class="text_container">
        <p>休むのも仕事のうち</p>
        <span class="circle">
          <img src="../../assets/img/common/arrow_pink.svg" />
        </span>
      </div>
      <img src="../../assets/img/top/fukidashi.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "beer",
  data() {
    return {
      class_name: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.class_name = "animation";
    }, 0);
  },
};
</script>

<style scoped>
.img_btn_container {
  width: 17.8%;
  bottom: 52.3%;
  left: 4.3%;
}

.fukidashi_container {
  width: 100%;
  top: -49%;
  left: -61%;
}

.text_container {
  margin-top: calc(50% - 14px);
}

.animation {
  animation: 8s kurukuru linear infinite;
}

/* 回転するアニメーション */
@keyframes kurukuru {
  0% {
    transform: rotate(0);
  }
  18% {
    transform: rotate(140deg);
  }
  50% {
    transform: rotate(180deg);
  }
  68% {
    transform: rotate(320deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .img_btn_container {
    width: 17%;
    bottom: 46.6%;
    left: 6.2%;
  }
  .fukidashi_container {
    display: none!important;
  }
}
</style>
