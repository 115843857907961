<template>
  <div class="wrapper">
    <table>
      <tbody>
        <tr>
          <td>個室30日</td>
          <td>45,000円</td>
        </tr>
        <tr>
          <td>ドミトリー20日</td>
          <td>22,000円</td>
        </tr>
        <tr>
          <td>ドミトリー15日</td>
          <td>19,000円</td>
        </tr>
        <tr>
          <td>ドミトリー10日</td>
          <td>15,000円</td>
        </tr>
        <tr>
          <td>ドミトリー5日</td>
          <td>9,000円</td>
        </tr>
      </tbody>
    </table>
    <img src="../../assets/img/modal_content/couple.png" />
  </div>
</template>

<script>
export default {
  name: "price",
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  padding: 32px 0;
  position: relative;
}
table {
  width: 300px;
  max-width: 100%;
  margin: 0 auto;
}
table tr {
  border-top: 1px solid #69397f;
  padding: 0 8px;
}
table tr:last-child {
  border-bottom: 1px solid #69397f;
}
table tr td {
  padding: 12px 0;
  font-size: 20px;
  color: #e6428d;
}

table tr td:first-child {
  text-align: left;
  padding-left: 8px;
}

table tr td:last-child {
  text-align: right;
  padding-right: 8px;
}

img {
  width: 168px;
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media only screen and (max-width: 550px) {
  .wrapper {
    padding-top: 16px;
  }
  img {
    bottom: 16px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding-top: 16px;
  }
  img {
    bottom: 16px;
    width: 136px;
  }
}

@media only screen and (max-width: 475px) {
  .wrapper {
    padding: 32px 16px 0;
  }
  img {
    bottom: 24px;
    width: 160px;
  }
}

@media only screen and (max-width: 420px) {
  table tr td {
    font-size: 16px;
  }
}

@media only screen and (max-width: 360px) {
  .wrapper {
    padding-top: 8px;
  }
  img {
    bottom: 16px;
    width: 136px;
  }
}
</style>
