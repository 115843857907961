<template>
  <div class="bar_wrapper">
    <span class="bar active"></span>
    <span class="bar active"></span>
    <span class="bar active"></span>
    <span class="bar active"></span>
    <span class="bar"></span>
    <span class="bar"></span>
  </div>
  <div class="img"></div>
  <div class="question_wrapper">
    <div class="question_number">Q4</div>
    <p class="question_text">冷蔵庫に入れてたあなたの食材が食べられました…</p>
  </div>
  <div class="input_container" v-on:click="next('a')">シェアハウスだからしょうがない</div>
  <div class="input_container" v-on:click="next('b')">おこです</div>
  <div class="input_container" v-on:click="next('c')">食材の量が少なすぎたいか…</div>
</template>

<script>
export default {
  name: "question4",
  methods: {
    next(input_value) {
      let point = 0;
      let is_syachiku = false;
      let normal_point = 0;

      if (input_value == "b") {
        point = -5;
      } else if (input_value == "c") {
        point = 5;
      }

      this.$emit("next", point, normal_point, is_syachiku);
    },
  },
};
</script>

<style scoped>
.img {
  background-image: url("../../assets/img/question/question_4.png");
}
</style>
