<template>
  <div class="img_btn_container" v-bind:class="class_name">
    <img src="../../assets/img/top/koba.png" />
    <div class="fukidashi_container koba" v-bind:style="{display:fukidashi_display}">
      <div class="text_container">
        <p>自宅警備中</p>
        <span class="circle">
          <img src="../../assets/img/common/arrow_pink.svg" />
        </span>
      </div>
      <img src="../../assets/img/top/fukidashi.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "koba",
  props: {
    is_hover: {
      type: Boolean,
      value: false,
    },
  },
  data(props) {
    return {
      class_name: "",
      fukidashi_display: this.getFukidashiDisplay(props.is_hover),
    };
  },
  mounted() {
    setTimeout(() => {
      this.class_name = "animation";
    }, 0);
  },
  methods: {
    getFukidashiDisplay(is_hover) {
      if (is_hover) {
        return "none";
      }

      return "block";
    },
  },
  watch: {
    is_hover: {
      handler: function (new_is_hover) {
        this.fukidashi_display = this.getFukidashiDisplay(new_is_hover);
      }
    },
  },
};
</script>

<style scoped>
.img_btn_container {
  width: 24.2%;
  bottom: 72%;
  left: 59.9%;
}

.img_btn_container .fukidashi_container.koba {
  display: block;
}

.fukidashi_container {
  width: 80%;
  top: -20%;
  left: -61%;
}

.text_container {
  margin-top: calc(50% - 14px);
}

.animation {
  animation: 4.5s fuwafuwa infinite;
}

@keyframes fuwafuwa {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media only screen and (max-width: 600px) {
  .img_btn_container {
    width: 23%;
    bottom: 71%;
    left: 60%;
  }
  .fukidashi_container {
    display: block!important;
    width: 110%;
    top: -50%;
    left: -87%;
  }
}
</style>
