<template>
  <div class="result_wrapper">
    <p class="subtitle">グットニート診断</p>
    <p class="title">結果発表</p>
    <img class="img_result" src="../../assets/img/question/result.png" />
    <p class="description">この宿はグットニートの方以外は<br class="br450">泊まれません</p>
    <div class="notice">
      <input type="checkbox" value="1" id="is_check" v-model="is_check" />
      <label for="is_check">私はバッドニートではありません</label>
    </div>
    <div class="question_button" v-on:click="next">
      <div class="text">診断結果を表示する</div>
      <div class="circle">
        <img src="../../assets/img/top/arrow.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "announcement",
  data() {
    return {
      is_check: null,
    };
  },
  methods: {
    next() {
      if(this.is_check){
        this.$emit("next", 0, false);

      }else{
        alert("バッドニートは診断結果を表示できません。")

      }
    },
  },
};
</script>

<style scoped>
.result_wrapper {
  text-align: center;
}
.result_wrapper p {
  color: #e6428d;
}
.subtitle {
  margin-top: 48px;
  font-size: 30px;
  letter-spacing: 1px;
}
.title {
  margin-top: 16px;
  font-size: 64px;
  letter-spacing: 2px;
}
.img_result {
  margin: 16px auto 0;
  width: 268px;
  max-width: 100%;
}
.notice {
  margin: 24px auto 0;
  padding: 12px 20px;
  border-radius: 4px;
  background-color: #f1ecf3;
  color: #69397f;
  width: fit-content;
}
.notice label{
  margin-left: 4px;
}
.description{
  line-height: 20px;
}
.question_button {
  width: 100%;
  margin: 48px auto 16px;
  position: relative;
  cursor: pointer;
  top: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #69397d;
  text-decoration: none;
  padding: 32px 0;
  border-radius: 8px;
  box-shadow: 0 8px 0 rgba(105, 57, 125, 0.15);
  transition: all 0.3s;
}
.question_button:hover {
  top: 8px;
  box-shadow: 0 0 0 rgba(105, 57, 125, 0.15);
  opacity: 0.7;
}

.question_button .text {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #fff;
  display: block;
  padding: 0 40px;
}

.question_button .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    width: 36px;
    height: 36px;
    position: absolute;
    right: 32px;
}
.br450{
  display: none;
}

@media only screen and (max-width: 450px) {
  .question_button .circle{
    right: 16px;
  }
  .br450{
    display: block;
  }
}
@media only screen and (max-width: 360px) {
  .notice{
    padding: 12px 8px;
    font-size: 14px;
  }
}
</style>
