<template>
  <div class="modal" v-if="tv_modal_code == 'show'" v-on:click="close">
    <div class="container" v-on:click.stop>
      <img class="tv_img" src="../../assets/img/tv/tv.png" />
      <iframe
        width="496"
        height="370"
        src="https://www.youtube.com/embed/QuAu1EEVLLs"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="back_container">
      <img src="../../assets/img/tv/close.svg" class="circle" />
      <span>トップに戻る</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "tv_modal",
  props: {
    tv_modal: {
      type: String,
      default: null,
    },
  },
  watch: {
    tv_modal: {
      handler: function (new_tv_modal) {
        this.tv_modal_code = new_tv_modal;
      },
    },
  },
  data(props) {
    return {
      tv_modal_code: props.tv_modal,
    };
  },
  methods: {
    close() {
      // this.modal_code = "none";
      this.$emit("close_tv_modal");
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
}
.container {
  position: relative;
}
iframe {
  position: absolute;
  top: 129px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.back_container {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: absolute;
  color: #000;
  right: calc(50% - 500px);
  font-size: 24px;
  letter-spacing: 3px;
  cursor: pointer;
  top: 0;
  bottom: 0;
  text-align: center;
}
.tv_img{
  width: 680px
}
.circle {
  position: relative;
  width: 36px;
  height: 36px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 1100px) {
  .back_container {
    right: 50px;
  }
}

@media only screen and (max-width: 970px) {
  .back_container {
    writing-mode: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    left: 0;
    bottom: 24px;
    top: auto;
  }
  .circle {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  .tv_img {
    width: 580px;
  }
  iframe {
    top: 97px;
    width: 464px;
    height: 343px;
  }
}

@media only screen and (max-width: 600px) {
  .tv_img {
    width: 500px;
  }
  iframe {
    top: 78px;
    width: 416px;
    height: 304px;
  }
}

@media only screen and (max-width: 520px) {
  .tv_img {
    width: 400px;
  }
  iframe {
    top: 65px;
    width: 327px;
    height: 240px;
  }
}

@media only screen and (max-width: 420px) {
  .tv_img {
    width: 360px;
  }
  iframe {
    top: 60px;
    width: 287px;
    height: 212px;
  }
}
@media only screen and (max-width: 375px) {
  .tv_img {
    width: 300px;
  }

  iframe {
    top: 49px;
    width: 244px;
    height: 180px;
  }
}
</style>
