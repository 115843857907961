<template>
  <router-link
    to="/"
    class="logo_link"
    v-bind:style="{ top: top, right: right, bottom: bottom, left: left }"
  >
    <img src="../../assets/img/common/logo.png" />
  </router-link>
</template>

<script>
export default {
  name: "logo",
  props: {
    top: {
      type: String,
      default: "auto",
    },
    right: {
      type: String,
      default: "auto",
    },
    bottom: {
      type: String,
      default: "auto",
    },
    left: {
      type: String,
      default: "auto",
    },
  },
};
</script>

<style scoped>
.logo_link {
  position: fixed;
  z-index: 50;
}
img {
  width: 120px;
}
@media only screen and (max-width: 768px) {
  .logo_link{
    width: 85px;
    top: 20px!important;
    left: 10px!important;
  }
}
</style>
