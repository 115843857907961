<template>
  <div class="container">
    <img class="tv" src="../../assets/img/top/tv.png" />
    <img class="kumo" src="../../assets/img/common/kumo_3.svg" />
    <div class="fukidashi">
      <img src="../../assets/img/top/tv_fukidashi.svg" />
      <div class="text_container">
        <p>CM見る？</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "kumo3",
};
</script>

<style scoped>
.container {
  position: fixed;
  width: min-content;
  cursor: pointer;
  z-index: 20;
  bottom: -80px;
  right: -60px;
}

.container:hover .fukidashi {
  display: block;
}

.fukidashi {
  display: none;
  position: absolute;
  bottom: 120px;
  right: 248px;
}

.text_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 4px 3px 0;
}

.tv {
  position: absolute;
  bottom: 96px;
  right: 80px;
}

.kumo {
  width: 320px;
  animation: 3s mokumoku infinite;
}

@keyframes mokumoku {
  0%,
  100% {
    width: 320px;
    height: 300px;
  }
  10%,
  90% {
    width: 315px;
    height: 295px;
  }
  50% {
    width: 300px;
    height: 290px;
  }
}
@media only screen and (max-width: 600px) {
  .container {
    bottom: -40px;
    right: -30px;
  }
  .kumo {
    animation: 3s mokumoku_sp infinite;
  }
  .tv{
    bottom: 44px;
    right: 37px;
    width: 70px;
  }
}

@keyframes mokumoku_sp {
  0%,
  100% {
    width: 150px;
    height: 140px;
  }
  10%,
  90% {
    width: 145px;
    height: 135px;
  }
  50% {
    width: 140px;
    height: 130px;
  }
}

@media only screen and (max-width: 520px) {
  .container {
    bottom: -40px;
    right: -30px;
  }
  .kumo {
    animation: 3s mokumoku_sp_small infinite;
  }
  .tv{
    bottom: 44px;
    right: 37px;
    width: 55px;
  }
}

@keyframes mokumoku_sp_small {
  0%,
  100% {
    width: 130px;
    height: 120px;
  }
  10%,
  90% {
    width: 125px;
    height: 115px;
  }
  50% {
    width: 120px;
    height: 110px;
  }
}
</style>
